// ==================================================
// Typography
// ==================================================

body {
	font-size: 16px;
	line-height: 1.4;
	@apply font-sans font-light text-navy;
	@screen lg {
		font-size: 20px;
		line-height: 1.46;
	}
}

strong {
	@apply font-bold;
}

address {
	@apply not-italic text-green-300;
}

hr {
    @apply border-0 border-t border-white;
}

cite {
	font-size: 12px;
	max-width: 745px;
    @apply ml-3 font-sans font-bold text-navy;
}

small, .small-text {
    line-height: 1.4;
    @screen lg {
        font-size: 17px;
    }
    @screen xl {
        font-size: 18px;
    }
}

.large-text {
    @screen lg {
        font-size: 22px;
    }
}


// --------------------------------------------------
// Headings
// --------------------------------------------------

.h1 {
    font-size: 32px;
    line-height: 1.2;
    @apply font-headings;
    @screen lg {
        font-size: 56px;
    }
}

.h2 {
    font-size: 32px;
    line-height: 1.2;
    @apply font-headings;
    @screen lg {
        font-size: 44px;
    }
}

.h3 {
    font-size: 24px;
    line-height: 1.3;
    @apply font-headings;
    @screen lg {
        font-size: 32px;
    }
}

.h4 {
    font-size: 20px;
    line-height: 1.3;
    @apply font-headings;
    @screen lg {
        font-size: 24px;
    }
}

.h4--alt {
    font-size: 20px;
    line-height: 1.28;
    @apply font-sans font-bold;
    @screen lg {
        font-size: 22px;
    }
}

.h5 {
    font-size: 17px;
    line-height: 1.2;
    letter-spacing: 1px;
    @apply font-sans font-bold uppercase;
}

.h5--alt {
    font-size: 17px;
    line-height: 20.4px;
    letter-spacing: 0.0625rem;
    font-family: gt-walsheim, Segoe UI;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 1px;
    text-align: left;

}

.h6 {
    @extend .h5;
}


// --------------------------------------------------
// Lists
// --------------------------------------------------

.list-tabs__item {
    @apply inline-block mb-2 mr-2;
}

.list-tabs__link {
    line-height: 1;
    border-radius: 5px;
    @apply inline-block px-4 py-2 border border-navy border-opacity-10;
    @screen lg {
        font-size: 17px;
    }
}

.list-tabs__link:hover {
    @apply bg-navy border-opacity-100 text-white;
}

.list-bullets__item {
    @apply relative pl-4 mb-1;
    @screen lg {
        @apply mb-2 pl-6;
    }
}

.list-bullets__item:before {
    content: '';
    width: 5px;
    height: 5px;
    top: 12px;
    @apply absolute left-0 block bg-navy rounded-full;
}


// --------------------------------------------------
// Tables
// --------------------------------------------------

table {
	border-collapse: collapse;

	td,
	th {
		padding: 10px;
		border-right: 1px solid rgba($dark-gray, 0.15);
		word-break: break-word;

		&:first-child {
			border-left: 1px solid rgba($dark-gray, 0.15);
		}
	}

	tr {
		border-bottom: 1px solid rgba($dark-gray, 0.15);

		&:first-child {
			border-top: 1px solid rgba($dark-gray, 0.15);
		}
	}

	thead {
		tr {
			background-color: rgba($dark-gray, 0.15);
		}

		th {
			font-weight: $font-weight-bold;
			color: $light-gray;
		}
	}

	tfoot {
		td {
			border: none;

			&:first-child {
				border: none;
			}
		}
	}
}


// --------------------------------------------------
// Images
// --------------------------------------------------

img {
	// WYSIWYG image styles
	&.left {
		float: left;
		margin-right: $grid-gutter-width/2;
	}

	&.right {
		float: right;
		margin-left: $grid-gutter-width/2;
	}

	&.center {
		display: block;
		margin: 0 auto;
	}
}


// --------------------------------------------------
// WYSIWYG Styles
// --------------------------------------------------

.cms-content {

    blockquote,
    table,
    .leftAlone {
        @apply my-8;
        @screen lg {
            @apply my-12;
        }
    }

    blockquote p {
        @screen lg {
            font-size: 24px;
            line-height: 1.4;
        }
    }

    blockquote p:before {
        content: open-quote;
    }

    blockquote p:after {
        content: close-quote;
    }

	img {
        @apply block mx-auto;
	}

    h1 {
        @extend .h1;
    }

    h2 {
        @extend .h2;
    }

    h1, h2 {
        @apply mb-8;
        @screen lg {
            @apply mb-10;
        }
        @screen xl {
            @apply mb-12;
        }
    }

    h3 {
        @extend .h3;
        @apply mb-4;
        @screen lg {
            @apply mb-6;
        }
    }

    .h4,
	h4 {
		@apply mb-4 font-bold text-navy font-sans;
	}

    h5 {
        @extend .h5;
        @apply mb-4;
    }

	p,
    ul,
    address {
        @apply mb-5;
		&:last-child {
			@apply mb-0;
		}
	}

    p + h3,
    ul + h3,
    ol + h3,
    p + h4,
    ul + h4,
    ol + h4,
    p + h5,
    ul + h5,
    ol + h5 {
        @apply mt-8;
        @screen lg {
            @apply mt-10;
        }
        @screen xl {
            @apply mt-12;
        }
    }

	a {
        @apply border-b border-current;
		&:hover {
			@apply text-green-300;
		}
	}

	a.btn {
		border-bottom-width: 0px;
	}
	a.btn:hover {
		border-bottom-width: 0px;
		color: rgb(0, 70, 90);
	}

    li {
        @extend .list-bullets__item;
    }
}

.cms-content--alt {
    h5 {
        font-size: 18px;
        line-height: 1.4;
        letter-spacing: 0;
        @apply font-light normal-case;
        @screen lg {
            font-size: 24px;
            @apply mb-12;
        }
    }
}

.list-links{
    border-bottom:1px solid #00465A;
    a {
        display:block;
    }
}
