// ==================================================
// Engage Us Form
// ==================================================



.l-engage-us-form {
	//color: #fff;

	.l-form {
		.field {
			@apply w-full;
		}

		.fieldgroup-field {
			@apply mb-7;
		}
	}

	.field .l-form__input.text label, 
	.field .l-form__input.dropdown label, 
	.field .l-form__input.textarea label {
    	display: none;
	}

	input + .fieldholder-small-label {
		display: block !important;
		@apply pt-2;
	}

	.fieldholder-small {
		padding: 0 !important;
		background-color: transparent !important;
	}

	label.left {
		@apply text-green-400 mb-4 font-sans font-bold block;
		font-size: 1.375rem;
	}

	.field {
		&:nth-child(2),
		&:nth-child(3) {
			.fieldgroup {
				@screen lg { 
					@apply flex flex-wrap justify-between;

					.fieldgroup-field {
						width: calc(50% - 1rem);
					}
				}
			}
		}
	}

	#EngageUsForm_DifferentAddress_Holder {
		@apply flex flex-row-reverse justify-end;

		label {
			@apply text-green-400;
		}
	}

	.l-form__input--checkbox {
		label {
			@apply text-green-400;
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: no-underline;
		}
	}

	.c-bg-title {
		color: #2d6d2b;
	}

	.container {
		position: relative;
	}

	&__title {
		position: relative;
		max-width: 770px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3rem;
	}

	.l-form__field--fw.text-white {
		@apply text-green-400 mb-4 pt-2 text-base;

		li {
			@apply mb-4 relative;

			&:before {
				content: "";
				width: 4px;
				height: 4px;
				@apply bg-green-400 absolute top-0 left-0 rounded-full;
				margin-top: 11px;
				margin-left: -24px;
			}
		}
	}

	.l-form__label {
		color: #fff;
	}

	.l-form__field:not(.l-form__field--checkbox) .l-form__label:not([for="DifferentAddress"]) {
		@apply sr-only;
	}

	legend {
		color: #fff;
	}

	.l-form__input--checkbox {
		background-color: transparent;
		border-color: #fff;
		color: #fff;
	}

	#FieldEngageUsNewsletter,
	#FieldTermsAndConditions {
		margin-top: 1rem;
	}

	#FieldAccountName,
	#FieldBusinessStatus,
	#FieldNatureOfAdvice,
	#FieldOtherBusinessStatus,
	#FieldRelationshipToClient,
	#FieldVerificationMethod {
		width: 100%;
	}

	ul {
		margin-top: 0;
		margin-left: 2.2rem;
	}

	.l-form__field--submit {
		width: 100%;
	}

	&__downloads {
		margin-top: 2rem;

		.l-resources__table__file-info {
			color: $brand-bg;
		}
	}
	@include desktop {
		//padding-top: 240px;
		[type="submit"] {
			float: right;
		}
	}
	@include mobile {
		[type="submit"] {
			width: 100%;
		}

		ul {
			margin-left: 0;
		}

		#FieldDifferentAddress {
			margin-top: $grid-gutter-width/2;
			margin-bottom: 0;
		}

		.c-bg-title {
			display: none;
		}

		#FieldRegistrationNumber {
			order: 10;
		}

		.l-form__field--submit {
			text-align: center;
		}
	}

	.flatpickr-input {
		background-color: #ffffff;
		font-family: Gotham SSm A, Gotham SSm B, sans-serif;
	}
}

span.flatpickr-day.selected,
span.flatpickr-day.selected:hover {
	background: #29af50;
	border: 0;
}

.flatpickr-current-month .numInputWrapper {
	width: 8ch;
}

.l-form__field--recaptcha {
	display: block;
	width: 100%;
}

.cms-content--engage-us-page {
	@include desktop {
		//margin-bottom: 60px;
	}
}

label[for="DateOfBirth"] {
	@include mobile {
		position: static !important;
		width: auto !important;
		height: auto !important;
		margin: 0 !important;
		clip: auto !important;
	}
}

form + p {
 @apply mt-10;
}
