
// ==================================================
// Block / Section Styles
// ==================================================

.help{
    .l-page-header{
        margin-top: 0;
    }
    .l-page-header__text{
        @media only screen and (max-device-width: 768px){
            @apply px-4;
        }
    }
}
.bg-custom-white {
    .l-page-header{
        background-color:#f3f7f1;
    }
    .l-page-header__image{
        background-color:#f3f7f1;
    }
    .l-page-header__image:after {
        background-color: #f3f7f1;
    }
    
}
.c-feature.bg-green{
    background-color: #00465a;
    @apply text-white;
    .c-feature__image:before{
        background-color: #00465a;
    }
}
.tile-link{
    .link-text:hover {
        border-color: transparent;
        color: #1EED93;
    }
    .list-links {
        border-bottom: 2px solid #1EED93;
    }
}
.no-list-links{
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 24px;
}
.border-lime{
    --tw-border-opacity: 1;
    border-color: #1EED93;
    border-left: 2px solid #1EED93;
}
.section-accordion{
    .c-contact-locations{
        margin-top: 0 !important;
    }
    .c-accordion__title:hover{
        .c-contact-locations__toggle-btn svg{
            stroke: #01AA96 !important;
        }
        .h4{
            color: #01AA96;
        }
    }
    .bg-white {
        background-color: #ffffff;
        @apply pl-0;
        .h4 {
            color: #0D1435;
        }
        .h4:hover{
            color: #01AA96;
        }
        .c-contact-locations__toggle-btn:hover{
            color: #01AA96;
        }
        .c-accordion--active{
            .h4{
                color: #01AA96;
            }
            border-bottom-width: 0px;
            .c-accordion__inner{
                border-bottom-width: 1px;
                position: relative;
                --tw-border-opacity: 1;
                border-color: rgba(0, 70, 90, var(--tw-border-opacity));
                --tw-bg-opacity: 1;
            }
        }
        .cms-content{
            color: #0D1435;
        }
    }
    .c-contact-locations a {
        border-bottom-width: 1px;
        border-color: #0d1435;
    }
    p{
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
        font-size: 20px;
    }
}
.service-cards:hover{
    .line-clamp-3{
        text-decoration: underline;
    }
}

