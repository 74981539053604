// ==================================================
// Flexbox Grid System
// ==================================================

* {
	box-sizing: border-box;
}

.container {
	width: 100%;
	max-width: $container-width;
	margin: 0 auto;

	&--small {
		max-width: $container-width-small;
	}

	&--large {
		max-width: $container-width-large;
	}

	&--xlarge {
		max-width: $container-width-xlarge;
	}

	&--fw {
		max-width: none;
		padding-left: 10vw;
		padding-right: 10vw;
	}

	&--no-padding {
		padding-left: 0;
		padding-right: 0;
	}
	@include mobile {
		padding-left: $grid-gutter-width/2;
		padding-right: $grid-gutter-width/2;

		&--no-padding {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.grid-box {
	@apply flex flex-wrap w-full;

	&--reverse {
		flex-direction: row-reverse;
	}

	&--v-center {
		align-items: center;
	}

	&--no-gutter {
		margin: 0;

		.col {
			padding: 0;
		}
	}

	&--centered {
		justify-content: center;
	}
}

.col {
	flex: 1;
	padding: 0 $grid-gutter-width/2;

	&--end {
		margin-left: auto;
	}
	@include mobile {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}
@mixin generate-xs-number-columns($n) {
	@for $i from 1 through $n {
		$grid-width: (100% / $n) * $i;

		.col--xs-#{$i} {
			flex: none;
			width: $grid-width;
			// IE11 fix
			@media all and (-ms-high-contrast: none) {
				max-width: $grid-width;
			}
		}
	}
}
@mixin generate-sm-number-columns($n) {
	@for $i from 1 through $n {
		$grid-width: (100% / $n) * $i;

		.col--sm-#{$i} {
			flex: none;
			width: 100%;
			@media screen and (min-width: $screen-sm-min) {
				width: $grid-width;
			}
			// IE11 fix
			@media all and (-ms-high-contrast: none) {
				max-width: $grid-width;
			}
		}
	}
}
@mixin generate-md-number-columns($n) {
	@for $i from 1 through $n {
		$grid-width: (100% / $n) * $i;

		.col--md-#{$i} {
			flex: none;
			width: 100%;
			@media screen and (min-width: $screen-md-min) {
				width: $grid-width;
			}
			// IE11 fix
			@media all and (-ms-high-contrast: none) {
				max-width: $grid-width;
			}
		}
	}
}
@mixin generate-lg-number-columns($n) {
	@for $i from 1 through $n {
		$grid-width: (100% / $n) * $i;

		.col--lg-#{$i} {
			flex: none;
			width: 100%;
			@media screen and (min-width: $screen-lg-min) {
				width: $grid-width;
			}
			// IE11 fix
			@media all and (-ms-high-contrast: none) {
				max-width: $grid-width;
			}
		}
	}
}
@mixin generate-xs-equal-columns($n-title, $n) {
	.col--xs-#{$n-title} {
		flex: none;
		width: 100% / $n;
		// IE11 fix
		@media all and (-ms-high-contrast: none) {
			max-width: 100% / $n;
		}
	}
}
@mixin generate-sm-equal-columns($n-title, $n) {
	.col--sm-#{$n-title} {
		flex: none;
		width: 100%;
		@media screen and (min-width: $screen-sm-min) {
			width: 100% / $n;
		}
		// IE11 fix
		@media all and (-ms-high-contrast: none) {
			max-width: 100% / $n;
		}
	}
}
@mixin generate-md-equal-columns($n-title, $n) {
	.col--md-#{$n-title} {
		flex: none;
		width: 100%;
		@media screen and (min-width: $screen-md-min) {
			width: 100% / $n;
		}
		// IE11 fix
		@media all and (-ms-high-contrast: none) {
			max-width: 100% / $n;
		}
	}
}
@mixin generate-lg-equal-columns($n-title, $n) {
	.col--lg-#{$n-title} {
		flex: none;
		width: 100%;
		@media screen and (min-width: $screen-lg-min) {
			width: 100% / $n;
		}
		// IE11 fix
		@media all and (-ms-high-contrast: none) {
			max-width: 100% / $n;
		}
	}
}
@mixin generate-xs-order($n) {
	@for $i from 1 through $n {
		.col--xs-order-#{$i} {
			order: $i;
		}
	}
}
@mixin generate-sm-order($n) {
	@for $i from 1 through $n {
		.col--sm-order-#{$i} {
			@media screen and (min-width: $screen-sm-min) {
				order: $i;
			}
		}
	}
}
@mixin generate-md-order($n) {
	@for $i from 1 through $n {
		.col--md-order-#{$i} {
			@media screen and (min-width: $screen-md-min) {
				order: $i;
			}
		}
	}
}
@mixin generate-lg-order($n) {
	@for $i from 1 through $n {
		.col--lg-order-#{$i} {
			@media screen and (min-width: $screen-lg-min) {
				order: $i;
			}
		}
	}
}
/* stylelint-disable declaration-no-important */
@mixin generate-hidden-classes($size, $breakpoint) {
	@media screen and (max-width: $breakpoint) {
		.hidden--#{$size} {
			display: none !important;
		}
	}
}
@mixin generate-visible-classes($size, $breakpoint, $breakpoint-min) {
	.visible--#{$size} {
		display: none !important;
		@media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint) {
			display: block !important;
		}
	}

	.visible--#{$size}-inline {
		display: none !important;
		@media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint) {
			display: inline !important;
		}
	}

	.visible--#{$size}-inlineblock {
		display: none !important;
		@media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint) {
			display: inline-block !important;
		}
	}

	.visible--#{$size}-flex {
		display: none !important;
		@media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint) {
			display: flex !important;
		}
	}
}
/* stylelint-enable declaration-no-important */
@include generate-xs-number-columns($columns);
@include generate-xs-equal-columns(fifth, 5);
@include generate-sm-number-columns($columns);
@include generate-sm-equal-columns(fifth, 5);
@include generate-md-number-columns($columns);
@include generate-md-equal-columns(fifth, 5);
@include generate-lg-number-columns($columns);
@include generate-lg-equal-columns(fifth, 5);
@include generate-xs-order($columns);
@include generate-sm-order($columns);
@include generate-md-order($columns);
@include generate-lg-order($columns);
@include generate-hidden-classes(xs, $screen-xs);
@include generate-visible-classes(xs, $screen-xs, 0);
@include generate-hidden-classes(sm, $screen-sm);
@include generate-visible-classes(sm, $screen-sm, $screen-sm-min);
@include generate-hidden-classes(md, $screen-md);
@include generate-visible-classes(md, $screen-md, $screen-md-min);
@include generate-hidden-classes(lg, $screen-lg);
@include generate-visible-classes(lg, $screen-lg, $screen-lg-min);
