// ==================================================
// Basic Carousel
// ==================================================

.c-basic-carousel {

	.swiper-slide {
        max-width: 332px;
		@apply w-11/12 pr-8;
        @screen md {
            max-width: 388px;
            @apply w-5/12 pr-12;
        }
        @screen lg {
            max-width: none;
            @apply w-1/3 pr-16;
        }

        &:last-child {

            margin-right: calc(100% - (100% / 3.2));

            @screen md {
                margin-right: calc(100% - (100% / 2.5));
            }

            @screen lg {
                margin-right: calc(100% - (100% / 3));
            }
        }
	}

}

.c-basic-carousel__controls {
    width: auto;
    @apply px-4 mr-12;
}

.c-basic-carousel__slides {
    @apply pl-4;
    @screen lg {
        width: calc(100% - 140px);    // Width of controls
        @apply px-4;
    }
}
