// ==================================================
// Discover Welcome
// ==================================================

.l-discover-welcome {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	transition: transform $transition-duration, opacity $transition-duration;
	@apply flex justify-center items-center;

	&__card {
		width: 100%;
		max-width: 486px;
		padding: 0;
		margin: 0;

		&:before {
			background-color: #f6f6f6;
		}

		&__title {
			font-size: 24px;
			@apply m-0 p-6 bg-white font-bold text-navy;
		}

		.btn {
			width: 280px;
			@apply block mx-auto;
		}

		&__details {
			@apply bg-grey-100 p-6;
		}

		&__welcome {
			font-size: 18px;
		}

		&__disclaimer {
			font-size: 16px;
            @apply my-6;
		}

		&__download-cta {
            @apply p-6;
			border-top: 1px solid rgba(#979797, 0.35);

			h5 {
				margin-bottom: 0.2em;
			}
		}
	}

	&--disabled {
		pointer-events: none;
	}

	&--close {
		opacity: 0;
		pointer-events: none;
		transform: scale(0.75);

		.btn:hover {
			background-color: $brand-green;
		}
	}
	@include desktop {
		min-height: 768px;
		max-height: 1100px;
	}
	@include mobile {
		height: auto;
		padding: calc(#{$mobile-site-header-height + $grid-gutter-width/2}) $grid-gutter-width/2 $grid-gutter-width/2;
		align-items: flex-start;

		&__card {
			&__details,
			&__download-cta {
				padding: $grid-gutter-width/2;
			}

			&__download-cta {
				a {
					font-size: 0.9rem;
				}
			}
		}
	}
}
