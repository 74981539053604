// ==================================================
// Forms
// ==================================================

input,
select,
textarea,
label {
    outline: none;
    box-shadow: none;
	font-size: 16px;
	line-height: 1.4;
	@apply font-sans font-light text-navy appearance-none;
	@screen lg {
		font-size: 16px;
        line-height: 1.46;
	}
}

fieldset {
    @apply w-full p-0 border-0;
}

.field.nocaptcha {
	width: 100% !important;
}

.l-form {
	$top: &;

    @apply flex flex-wrap justify-between transition-opacity duration-500 ease-in-out;

	&--fullwidth {
		.l-form__field {
            @apply w-full;
		}
	}

	&--hidelabels {
		.l-form__label {
			@apply hidden;
		}
	}

	legend {
        @apply my-4 font-bold uppercase;
	}

	&__fieldset-inner {
        @apply flex flex-wrap justify-between;
	}

	.error {
		color: $error;
        @apply my-2 hidden;
		// &.required {
  //           @apply hidden;
		// }
	}

    .hide {
        display: none;
    }

    .select2-container {
        max-width: 100%;
    }

	&__error {
		color: $error;
        @apply my-2 hidden;
		&.required {
            @apply hidden;
		}
	}

	.field {
		@apply w-full mb-3;
		@screen lg {
            width: calc(50% - #{$grid-gutter-width/2});
			@apply mb-5;
		}

		&--fieldgroup,
		&--fw {
			@apply w-full;
		}

		&--double {
            @apply flex flex-wrap p-0;
		}

		&--file {
			min-height: 30px;
            @apply relative flex flex-wrap items-center mb-8;

			#{$top}__label {
				z-index: 1;
                @apply relative mb-0 mr-4 whitespace-nowrap;

				&:empty {
                    @apply hidden;
				}

				&:after {
					content: 'Choose file';
                    @apply inline-block ml-6 underline;
				}

				&:hover {
					color: $link-hover-color;
				}

				&:hover:after {
					color: $link-hover-color;
				}
			}
		}

	    &__field:last-child {
	        @apply mb-0
	    }

		&__label {
	        @apply sr-only;
		}

		&.l-form__input {
			@apply border-0;
			&.text,
			&.dropdown,
			&.textarea {
				label {
					@apply hidden;
				}
			}
		}

		&#ContactForm_Foobar_BusinessName_Holder.l-form__input,
		&.l-form__input.textarea {
			@apply w-full;
		}

		&.checkbox,
		&.optionset li {
			@apply flex justify-start;

			label {
				order: 2;
				user-select: none;
	            @apply not-sr-only m-0 cursor-pointer;
			}
		}

		&.optionset > label {
			@apply hidden;
		}

		ul.optionset {
			@apply border-0 p-0 bg-transparent;
		}

		.l-form__input {
			border-radius: 5px;
			@apply inline-block w-full px-4 py-2 bg-white font-sans text-navy overflow-ellipsis transition-colors duration-300 ease-in-out;

			@screen lg {
	            @apply px-6 py-3;
			}

			&::placeholder {
	            @apply opacity-100 text-navy;
			}

			&--select {
				background-image: url("#{$assets-path}images/svg/select-arrow.svg");
				background-position: center right 20px;
				padding-right: 48px;
	            @apply bg-no-repeat cursor-pointer;
			}

			&--textarea {
				height: $textarea-height;
				padding: $textarea-padding;
				resize: vertical;

				&::placeholder {
	                @apply text-navy;
				}
			}

			&--file {
	            @apply absolute opacity-0 w-auto h-auto p-0 bg-transparent border-0;
			}

			&:active,
			&:focus {
				box-shadow: none;
	            @apply border-navy;
			}

			&:focus:invalid {
				// border-color: $error;
			}

			&[disabled],
			&[readonly] {
				@apply bg-white bg-opacity-50;
			}

			&--checkbox {
				@apply cursor-pointer relative flex flex-wrap items-center mb-8 w-6 h-6 p-0 border-solid border-navy border-2 mr-3 flex-none bg-white;
				order: 1;
				border-radius: 0.3125rem;
	            @screen lg {
	                @apply mb-0;
	            }

				&:checked {
					@apply border-green-200 bg-green-200;
					background-image: url("#{$assets-path}images/svg/tick.svg");
					background-position: center;
	            	@apply bg-no-repeat;
				}

				&:focus {
					border-width: 2px;
				}
			}


			.radio {
	            @apply cursor-pointer relative flex flex-wrap items-center mb-4 border-0 w-6 h-6 p-0 border-solid border-navy border-2 mr-3 rounded-full;
				order: 1;

	            &:checked {
					@apply border-green-200 bg-white;
				}

				&:focus {
					border-width: 2px;
				}

	            &:after {
	            	content: "";
					width: 10px;
					height: 10px;
					left: 5px;
                    @apply inline-block absolute opacity-0 text-center duration-300 ease-in-out bg-green-200 rounded-full;
				}

				&:checked:after {
                    @apply opacity-100;
				}

				#{$top}__error {
					order: 5;
	                @apply w-full;
				}

			}

		&__description {
	        @apply my-0;
		}

		&--submitted {
			#{$top}__input:invalid {
				border-color: $error;
			}
		}
		// Form states
		&[data-state="invalid"] {
			#{$top}__input:invalid {
				border-color: $error;
				color: $error;

				& ~ .l-form__error {
	                @apply inline-block;
				}
			}
		}

		&[data-state="loading"] {
			.l-form__input,
			.l-form__label {
	            @apply opacity-25 pointer-events-none;
			}

			.l-form__input--file {
	            @apply opacity-0;
			}

			[type="submit"] {
	            @apply opacity-100 pointer-events-none;

				&:before {
					content: 'sending...';
	                @apply inline-block w-full text-center;
				}
			}
		}

		&__file-name:empty + &__file-reset-btn {
	        @apply hidden;
		}

		&__file-name {
			width: calc(100% - 48px);
	        @screen lg {
	            @apply w-full;
	        }
		}

		&__file-reset-btn {
			z-index: 2;
			width: 30px;
			height: 30px;
			background-image: url("#{$assets-path}svg/cross.svg");
			background-size: 40% auto;
	        @apply relative flex-shrink-0 ml-4 bg-transparent bg-center bg-no-repeat border-0;

			&:hover {
				transform: scale(1.08);
	            @apply cursor-pointer;
			}
		}
	}
}
}

.select2-results__option {
    @apply mb-0;
}

select::-ms-expand {
    @apply hidden;
}

#FieldBusinessName,
#FieldMessage {
    @apply w-full;
}

#FieldExpertise,
#FieldTeamLocation {
	@apply hidden;
    @screen lg {
        @apply block;
    }
}

#MemberLoginForm_LoginForm {
	.field.text {
		@apply mb-2;
	}
	input.text {
		border-radius: 5px;
		@apply inline-block w-full px-4 py-2 bg-white font-sans text-navy overflow-ellipsis transition-colors duration-300 ease-in-out border border-grey-200;

		@screen lg {
            @apply px-6 py-3;
		}
	}

	input[type=submit] {
		font-size: 14px;
		line-height: 1;
		letter-spacing: 2px;
		@apply inline-block px-10 py-4 bg-green-400 font-bold uppercase text-center text-green-200 rounded-full my-6 cursor-pointer;

		&:hover {
		    @apply bg-green-200 text-green-400;
		}
	}
}

.parsley-errors-list,
.parsley-errors-list {
	@apply ml-0 text-base mt-2;

	.parsley-custom-error-message {
		color: #ff0000;
	}
}

// --------------------------------------------------
// Form Errors
// --------------------------------------------------

.c-form-message {
    @apply w-full mb-4 py-4;

	p {
        // @apply font-bold;
	}

	&--error {
		color: $error;
		border-color: $error;
	}

	&--warning {
		border-color: $brand-warning;
	}

	&--success {
		background-color: #35f193;
		border-color: #35f193;
        @apply px-4;
        color: #0d1235;
        text-align: center;
	}
}
