// ==================================================
// Resource Form
// ==================================================

.c-resource-form {
  max-width: 500px;

  &__title {
    margin-bottom: 0.5em;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    letter-spacing: 1.49px;
  }

  .l-form__label {
    @apply sr-only;
  }

  .l-form__field {
    width: 100%;

    &--submit {
      display: flex;
      justify-content: center;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include desktop {
    [type="submit"] {
      min-width: 300px;
      margin-top: 1rem;
    }
  }

  @include mobile {
    &__title,
    &__description {
      text-align: center;
    }
  }
}
