html {
	margin-top: 0 !important;
}

body .buorg {
	position: fixed;
	height: 100%;
	background-color: rgba(0, 128, 0, 0.7);
}

body .buorg-pad {
	padding: 50px;
	line-height: 1.7em;
	width: 40%;
	text-align: left;
	background-color: white;
	border-radius: 0 0 20px;
	transform: translate(-50%, -50%);
	position: absolute;
	left: 50%;
	top: 50%;
}

body .buorg-pad p {
	font-family: $font-family-base;
	font-weight: normal;
}

body .buorg-icon {
	display: none;
}

body .browserUpdate-logo {
	display: flex;
	margin-top: 30px;
}

body .browserUpdate-logo > div {
	display: flex;
	width: 50%;
}

body .browserUpdate-logo a {
	display: flex;
}

body .browserUpdate-logo .browserUpdate-firefoxlogo {
	margin-right: 10px;
}

body .browserUpdate-logo .browserUpdate-chromelogo {
	margin-right: 10px;
}

body .browserUpdate-logo img {
	width: 30px;
	height: 30px;
}

body .browserUpdate-logo .browserUpdate-firefoxtext {
	font-size: 12px;
	color: green;
	font-family: $font-family-base;
	font-weight: 500;
}

body .browserUpdate-logo .browserUpdate-chrometext {
	font-size: 12px;
	color: green;
	font-family: $font-family-base;
	font-weight: 500;
}

body .browserUpdate-ignore #buorgig {
	border: 0;
	background-color: #9aca3c;
	font-size: 12px;
	line-height: 16px;
	padding: 12px 40px;
	height: auto;
	border-radius: 100px;
	margin: 30px auto 0;
	box-shadow: none;

	&:focus,
	&:hover {
		background-color: #585b5a;
	}
}
