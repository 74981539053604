// ==================================================
// Defaults
// ==================================================

::selection {
    // @apply bg-grey-100;
}

:focus,
button:focus {
    outline:none;
}

body,
html {
	scroll-behavior: smooth;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
    outline: none;
}

strong {
    @apply font-bold;
}

.site-main {
    @apply overflow-hidden;
}

img {
    @apply max-w-full h-auto;
}

a,
button,
svg {
	text-decoration: none;
    @apply transition duration-300 ease-in-out;
}

ul, ol {
    @apply list-none m-0 p-0;
}

td {
	word-break: break-word;
}

body.no-touch {
	.show-touch {
		display: none !important;
		pointer-events: none;
	}
}

body.has-touch {
	.show-touch {
		display: inline-block;
	}
}

@screen lg {
	.hide-desktop {
		display: none !important;
		pointer-events: none;
	}
}

@include mobile {
	.hide-mobile {
		display: none !important;
		pointer-events: none;
	}
}

.alert {
	color: $error;
	padding-bottom: 2rem;
}

.line-effect {
	@apply relative;

	h2, 
	.cms-content, 
	.c-feature__links {
		@apply bg-white;
	}

	&:before {
		content: "";
		width: 2px;
		@apply absolute bg-green-200 left-0 top-0 ml-4;
		height: 108%;
		z-index: -1;
		transform-origin: top left;
		transform: rotate(-20deg);

		@screen lg {
			@apply hidden;
		}
	}

	h2 {
		@apply relative;

		@screen lg {
			@apply text-center;
			&:before {
				content: "";
				width: 2px;
				@apply absolute bg-green-200;
				top: 50%;
               	left: 50%;
               	transform: translate(-50%, -50%) rotate(-20deg);
				height: 500px;
				z-index: -1;
			}
		}
	}
}

.umbMacroHolder {
	@screen lg {
		@apply pl-12;
	}
}

blockquote {
	@apply relative;
	@screen lg {

		.block {
			@apply py-12;
		}

		@apply pl-40;

		&:before {
			content: "";
			width: 2px;
			@apply absolute bg-green-200 left-0 top-0 ml-4;
			height: 108%;
			transform-origin: top left;
			transform: rotate(-20deg);
		}
	}
}
