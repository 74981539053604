// ==================================================
// Story
// ==================================================
$iframe-content-ratio: 740/1044;

body.StoryPage {
	.c-lightbox__close-btn {
		display: none;
	}
}

.l-story {
	overflow: hidden;
	width: 100%;

	&__placeholder {
		border: 0;
		padding: 0;
		filter: grayscale(100%);
		opacity: 0.3;

		&:hover {
			cursor: pointer;
		}
	}

	&__iframe-container {
		width: 100%;
	}

	iframe {
		display: block;
		width: $container-width;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		// set the height to match the aspect ratio of content 1044 x 740
		height: ($container-width * $iframe-content-ratio) + 10;
	}

	@include mobile {
		iframe {
			width: 100vw;
			height: calc(100vw * #{$iframe-content-ratio});
		}
	}

	@media screen and (min-width: #{$screen-md}) and (min-height: 600px) and (max-height: 800px) {
		iframe {
			width: (700px / $iframe-content-ratio) + 10;
			height: 700px;
		}
	}
}
