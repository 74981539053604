// ==================================================
// Values
// ==================================================

.l-values {
	&__card {
		display: flex;
		flex-wrap: wrap;
		$card: &;
        @apply relative overflow-hidden mb-8  shadow;

		&__image {
			flex: 0 0 100%;
			background:#F3F7F0;
			@include medium-up {
				flex: 0 0 30%;
			}
			picture {
	            @apply block w-full h-auto;
				img {
					margin:0px auto; 
				}
			}
		}

		&__text {
			flex: 0 0 100%;
			padding:50px 40px;
			@include medium-up {
				flex: 0 0 70%;
			}
		}
		&__description {
			@include large-up {
				padding: 1rem 2rem;
			}
		}

	}
}
