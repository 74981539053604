// ==================================================
// Video
// ==================================================

.c-video-wrapper {
	width: calc(100vw - #{$grid-gutter-width});
	max-width: 100%;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	background-color: #000;

	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__loading {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		text-align: center;
	}
}

.c-video-card {
	$top: &;
	position: relative;
	min-width: 280px;

	&__img {
		display: block;
		width: 100%;
		max-width: none;
	}

	&__btn {
		display: block;
		position: relative;

		&:hover {
			#{$top}__play-icon {

				circle {
					fill: rgba(255, 255, 255, 0.5);
				}

				path {
                    @apply fill-navy;
				}
			}
		}
	}

	&__play-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		svg circle,
		svg path {
			transition: fill $transition-duration;
		}
	}

	&__details {
		position: relative;
		left: -12px;
		margin-top: -50px;
		max-width: calc(100% - 60px);

		p {
			margin: 0;
		}
	}
    
	@include mobile {
		&__details {
			width: calc(100% - 20px);
			margin-top: -20px;
		}
	}
}
