// ==================================================
// Lightbox
// ==================================================

.c-lightbox {
	$top: &;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#343434, 0.9);
	z-index: $z-index-lightbox;
	will-change: opacity;
	transition: opacity 0.4s ease-in-out;
	opacity: 0;
	pointer-events: none;
	overflow: auto;
	overflow-x: hidden;

	h4 {
		@apply font-sans mb-4 font-bold;
	}

	&__close-btn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		top: 120px;
		right: 20px;
		line-height: 30px;
		min-height: 40px;
		padding: 5px;
		border: 0;
		background-color: transparent;
		outline: none;
		color: $brand-primary;
		text-transform: uppercase;
		font-weight: $font-weight-medium;
		font-size: 0;

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
		}

		svg {
			width: 18px;
			height: 18px;
			margin: 0;
			vertical-align: middle;
			transition: transform $transition-duration;
			stroke: $dark-gray;
		}

		&:hover {
			cursor: pointer;
		}

		&:focus svg,
		&:hover svg {
			stroke: $brand-primary;
		}

		&:hover span {
			border-color: #fff;
		}
	}

	&__container {
		align-self: flex-start;
		will-change: transform;
		transform: scale(0.95);
		transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
	}

	&__content {
		max-width: calc(100vw - #{$grid-gutter-width});
		margin-top: 100px;
		padding: 50px;
		background-color: #fff;
	}
	// this helps prevent a flash of scroll bars as the inner content animates large than the lightbox size
	&--animating {
		overflow: hidden;
	}

	&--open {
		opacity: 1;
		pointer-events: inherit;

		#{$top}__container {
			transform: scale(1);
		}
	}

	&--video {
		#{$top}__content {
			max-width: 1280px;
		}

		#{$top}__close-btn {
			top: 110px;
			right: 10px;
		}
	}

	&--discover-step-info {
		#{$top}__content {
			max-width: 768px;

			p:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--download &__content {
		h4 {
			margin-top: 2rem;
		}
	}
	@include desktop {
		&--discover-step-info {
			#{$top}__container {
				align-self: center;
			}
		}
	}
	@include mobile {
		&__content {
			margin-top: 15px;
			padding: $grid-gutter-width $grid-gutter-width/2;
		}

		&__close-btn {
			top: 15px;
			right: 0;
		}

		&--video {
			#{$top}__content {
				margin: 0;
				padding: 0;
			}

			#{$top}__close-btn {
				top: auto;
				right: 0;
				bottom: 100%;

				svg {
					stroke: #fff;
				}
			}

			#{$top}__container {
				align-self: center;
			}
		}
	}
	@include mobile-landscape {
		&--video {
			#{$top}__container {
				padding-top: 50px;
				align-self: flex-start;
			}

			#{$top}__close-btn {
				top: 0;
				bottom: auto;
			}
		}
	}
}
