// ==================================================
// Helpers
// ==================================================

@responsive {
	.row {
		max-width: 1332px;
		@apply flex flex-wrap mx-auto;
	}
}

@responsive {
	.column {
		@apply w-full px-4;
		@screen lg {
			@apply px-4;
		}
	}
}

.flush-right {
    @apply flex flex-wrap ml-4;
    @screen md {
        @apply flex-nowrap;
    }
    @media only screen and (min-width: 1332px) {    // Container width
        margin-left: calc((100% - 1300px) / 2);
    }
}

.margin {
	padding-top: 48px;
	padding-bottom: 48px;
	@screen lg {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}

.padding {
	padding-top: 48px;
	padding-bottom: 48px;
	@screen lg {
		padding-top: 58px;
		padding-bottom: 58px;
	}
}

.padding--xs {
	@screen lg {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}

.padding--sm {
	@screen lg {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.padding--lg {
	@screen lg {
		padding-top: 120px;
		padding-bottom: 120px;
	}
}

.padding--xl {
	@screen lg {
		padding-top: 140px;
		padding-bottom: 140px;
	}
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.icon {
    width: 20px;
    @apply inline-flex justify-center mr-4;
}

.media {
    padding-bottom: 56.25%;
    @apply relative h-0;
}

.media iframe {
    @apply absolute top-0 left-0 w-full h-full;
}
