// ==================================================
// Swiper
// ==================================================

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
}

.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform, opacity;
}

.swiper-slide-invisible-blank {
    visibility: hidden;
}

/* Auto height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
}


/* CSS mode */
.swiper-container-css-mode > .swiper-wrapper {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none;
}

.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start;
}

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: x mandatory;
}

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: y mandatory;
}

.next-btn,
.prev-btn {
	width: 60px;
	height: 60px;
    @apply flex justify-center items-center bg-green-200 rounded-full;

    &--alt {
        @apply bg-green-400;
        svg path {
            @apply fill-green-200;
        }
    }
}

.prev-btn {
    @screen lg {
        @apply mb-4;
    }
}

.next-btn:not(.swiper-button-disabled):hover,
.prev-btn:not(.swiper-button-disabled):hover {
    @apply bg-navy;
    svg path {
        @apply fill-green-200;
    }
}

.next-btn--alt:not(.swiper-button-disabled):hover,
.prev-btn--alt:not(.swiper-button-disabled):hover {
    @apply bg-green-200;
    svg path {
        @apply fill-green-400;
    }
}

.next-btn.swiper-button-disabled,
.prev-btn.swiper-button-disabled {
    @apply opacity-25;
}
