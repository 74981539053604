// ==================================================
// Fonts
// ==================================================

@font-face {
    font-family: "gt-walsheim";
    font-style: normal;
    font-weight: 300;
    src: url("../../fonts/GT-Walsheim-Light.woff2") format("woff2"), url("../../fonts/GT-Walsheim-Light.woff") format("woff");
}

@font-face {
    font-family: "gt-walsheim";
    font-style: normal;
    font-weight: 700;
    src: url("../../fonts/GT-Walsheim-Bold.woff2") format("woff2"), url("../../fonts/GT-Walsheim-Bold.woff") format("woff");
}

@font-face {
    font-family: "gt-super-display";
    font-style: normal;
    font-weight: 700;
    src: url("../../fonts/GT-Super-Display-Bold.woff2") format("woff2"), url("../../fonts/GT-Super-Display-Bold.woff") format("woff");
}

@font-face {
    font-family: "gt-super-display-trial";
    font-style: normal;
    font-weight: 700;
    src: url("../../fonts/GT-Super-Text-Regular-Trial.woff2") format("woff2"), url("../../fonts/GT-Super-Text-Regular-Trial.woff") format("woff");
}
