// ==================================================
// Discover Tool
// ==================================================

.l-discover-tool {
	position: relative;
	width: 100vw;
	height: 100vh;
	min-width: 100%;
	overflow: hidden;
	pointer-events: none;
	opacity: 0.3;
	will-change: height, opacity;
	transition: height $transition-duration*2, opacity $transition-duration*2;

	&--open {
		pointer-events: all;
		opacity: 1;
	}

	&--closed {
		svg [id*="line-"] {
			opacity: 1 !important;
			stroke-dashoffset: 0 !important;
		}
	}

	&__controls {
		display: flex;
		position: absolute;
		top: $grid-gutter-width + $site-header-height;
		left: 50%;
		z-index: 1;
		transform: translateX(-50%);
		width: 100%;
		max-width: 980px;
		justify-content: space-between;
		padding-left: $grid-gutter-width/2;
		padding-right: $grid-gutter-width/2;

		.btn {
			@apply flex justify-center items-center;
			transition: opacity $transition-duration, transform $transition-duration;

			&.hide {
				opacity: 0;
				pointer-events: none;
				transform: translateY(-100%);
			}

			&:hover {
				svg {
                    @apply stroke-navy fill-navy;
				}
			}
		}

		.btn svg {
			margin-top: auto;
			margin-bottom: auto;
            @apply stroke-green-200 fill-green-200;
		}

		@include mobile {
			top: $grid-gutter-width/2;

			.btn {
				width: auto;
				min-width: 0;
				height: $btn-height-small;
				padding: 0 $btn-hrz-padding-small/2;
			}
		}
	}

	&__back-btn svg {
		@apply mr-2;
	}

	&__restart-btn {
		margin-left: auto;
	}

	&__restart-btn svg  {
		@apply mr-2;
	}

	&__chart {
		width: 100%;
		height: 100%;
		overflow: hidden;

		svg {
			width: 100%;
			height: 100%;

			text,
			tspan {
				font-family: $font-family-base;
			}
			// SVG elements
			[id*="action-"],
			[id*="info-"],
			[id*="link-"] {
				&:hover {
					cursor: pointer;
				}
			}

			[id*="info-"],
			[id*="link-"] {
				&:hover {
					text-decoration: underline;
				}
			}

			[id*="action-"] {
				path,
				text {
					transition: fill $transition-duration;
				}

				path {
					fill: #1EED93;
				}

				&:hover path {
					fill: #0D1435;
				}

				&:hover text {
					fill: #1EED93;
				}
			}

			[id*="line-"] {
				transition: stroke-dashoffset 1200ms ease-in-out;
			}
		}
	}

	&--open {
		// SVG group
		[id*="step-"] {
			opacity: 0;
			pointer-events: none;
			transform: scale(0.85);
			will-change: opacity, transform;
			transition: opacity $transition-duration*2 ease-in-out, transform $transition-duration*2 cubic-bezier(0.175, 0.885, 0.32, 1.275);
			transform-origin: center;

			&[data-state="active"] {
				opacity: 1;
				transform: scale(1);
				pointer-events: inherit;
			}
		}
	}

	&--completed {
		height: calc(100vh - 90px);
	}

	@include desktop {
		&:not(.l-discover-tool--completed) {
			min-height: 768px;
			max-height: 1100px;
		}
	}

	@include mobile {
		height: calc(100vh - #{$mobile-site-header-height});

		&--completed {
			height: calc(100vh - 220px);
		}
	}
}
