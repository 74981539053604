// ==================================================
// Accordion
// ==================================================

.c-accordion {

	&__title:hover {
		@apply cursor-pointer;
	}

	&__content {
		transition-property: height;
		will-change: height;
		max-height: none;
		visibility: visible;
        @apply overflow-hidden block h-0 ml-0 duration-300 ease-in-out;
	}

	&__inner {
		float: left; // helps get better height calc
        @apply w-full;
	}
}
