// ==================================================
// Links
// ==================================================

.btn {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 2px;
    @apply inline-block px-10 py-4 bg-green-400 font-bold uppercase text-center text-green-200 rounded-full;
}

.btn--alt:active,
.btn:hover {
    @apply bg-green-200 text-green-400;
}

.btn--alt {
    @apply bg-green-200 text-navy;
}

.btn--alt:active,
.btn--alt:hover {
    @apply bg-navy text-green-200;
}

/*New Mobile button*/
.btn--mobile {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 2px;
    width:77% !important;
    height: 56px;
    padding-top: 1.4rem !important;
    padding-right: 2.5rem !important;
    padding-bottom: 1.4rem !important;
    padding-left: 2.5rem !important;
    @apply inline-block px-10 py-4 bg-green-400 font-bold uppercase text-center text-green-200 rounded-full;
}

.btn--alt--mobile:active,
.btn--mobile:hover {
    @apply bg-green-200 text-green-400;
}

.btn--alt--mobile {
    @apply bg-green-200 text-navy;
}

.btn--alt--mobile:active,
.btn--alt--mobile:hover {
    @apply bg-navy text-green-200;
}

/*mobile button end*/

/*start*/
.btn--portal {
    @apply bg-navy text-green-200;
    background-image: url("#{$assets-path}images/svg/portal-green-1.svg");
    background-position: center;
    background-repeat: no-repeat;
    padding: 1.45rem;
    margin: 0px 0px -18px 0px;
}

.btn--portal:active,
.btn--portal:hover {
    @apply bg-green-200 text-navy;
    background-image: url("#{$assets-path}images/svg/portal-teal-1.svg");
}

.btn--mobileportal {
    @apply bg-navy text-green-200;
    background-image: url("#{$assets-path}images/svg/portal-green-1.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 10px;
    padding: 1.7rem;
}

.btn--mobileportal:active,
.btn--mobileportal:hover {
    @apply bg-green-200 text-navy;
    background-image: url("#{$assets-path}images/svg/portal-teal-1.svg");
}


    /*end*/


.link-text {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 2px;
    padding-bottom: 2px;
    @apply inline-block font-bold uppercase border-b border-transparent whitespace-nowrap;
}

.btn--alt:active,
.link-text:hover {
    @apply border-current;
}

.btn + .link-text {
    @apply mt-7;
    @screen md {
        @apply mt-0 ml-8;
    }
    @screen xl {
        @apply ml-12;
    }
}

.link-block {
}

.link-block__link,
.link-block__image {
    @apply transition duration-500 ease-in-out;
}

.link-block__link {
    top: 50%;
    left: 50%;
    @apply opacity-0 absolute transform -translate-y-2/4 -translate-x-2/4 whitespace-nowrap;
}

.link-block:hover .link-block__link {
    @apply opacity-100 z-10;
}

.link-block:hover,
.c-insights-card:hover {
    .c-insights-card__text {
        h2,
        h4 {
            text-decoration: underline;
        }
    }
}

.c-card--news:hover {
    h2,
    h4 {
        text-decoration: underline;
    }
}

.person-card {
    a:hover ~ h4,
    h4:hover {
        text-decoration: underline;
    }
}

#onetrust-consent-sdk #onetrust-pc-btn-handler, #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
    color: #0D1435 !important;
    border-color: #0D1435  !important;
    background-color: #FFFFFF;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler {
    background-color: #0D1435 !important;
    border-color: #0D1435 !important;
    color: #FFFFFF;
}
