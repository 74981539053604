// ==================================================
// Payment CTA
// ==================================================

.l-payment-cta {
	padding-top: 175px;

	.container {
		position: relative;
		display: flex;
		justify-content: center;
	}

	.c-bg-title {
		transform: translate(-15%,-65%);
	}

	&__card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 0 0 50px;
		width: 100%;
		max-width: 1000px;
		text-align: center;
		box-shadow: 0 2px 12px rgba(#4f955e, 0.62);

		&:before {
			background-color: #f6f6f6;
		}

		&:focus:before,
		&:hover:before {
			height: 100%;
		}

		&__title {
			width: 100%;
			text-transform: uppercase;
			padding: 24px;
			margin: 0;
			font-size: 24px;
			font-weight: $font-weight-normal;
			background-color: #fff;
			color: #4a4a4a;
		}

		&__details {
			max-width: 500px;
			padding: 24px;
			border-bottom-right-radius: 20px;

			p {
				margin-top: 0;
			}
		}
	}
    
	@include mobile {
		background-color: $brand-bg;
		padding-top: $mobile-section-padding/2;
		padding-bottom: $mobile-section-padding/2;

		.c-bg-title {
			display: none;
		}

		&__card {
			padding-bottom: 0;
		}
	}
}
