// ==================================================
// Contact
// ==================================================

.l-form {
	.c-form-message--error {
		padding-left: 1rem;
		padding-right: 1rem;
		background-color: #971a18;
		border-color: #971a18;
		color: #fff;
	}

	#FieldNewsletter {
		@apply w-full mb-5;
	}

	&__full-width {
		width: 100% !important;
	}
}

.l-form__field--submit {
	@apply w-full;
}

.l-contact,
#Form_RegisterForm {

    &__team-filter,
    &__contact-form {
        @apply pt-12;
    }

	.c-contact-locations {
        @screen lg {
            z-index: 1;
            @apply relative mt-12;
        }
	}

	.l-contact__forms {
        @apply bg-white;
        @screen lg {
			left: -63px;
			@apply relative self-start pl-24 pr-12 py-12 shadow;
        }
        @screen xl {
			left: -80px;
			@apply pl-32 pr-16 py-16;
        }
	}

    .l-form__input {
        @apply border border-grey-200;

        &.l-form__full-width {
        	@apply w-full;
        }
    }

    .l-form__field--submit {
    	@apply w-full;
    }

    .l-form__input:focus {
        @apply border border-green-400;
    }

	.l-form__label:not([for="Newsletter"]) {
		@apply sr-only;
	}

	.l-form__field--textarea {
		@apply w-full;
	}
}

#Form_RegisterForm {
	.field.l-form__input {
		border: 0;
	}
}
