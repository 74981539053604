// ==================================================
// Site Nav
// ==================================================

// Top menu
.c-site-nav {
	@apply flex h-full;

	&__item {
		@apply flex items-center min-h-full;

		> a {
			&:hover {
				@apply text-green-200;
			}
		}

		&:hover {
			> a {
				@apply text-green-200;
			}
		}
	}

	// &__item.open {
	// 	.c-site-nav__sub-menu-wrapper {
	// 		max-height: 9999px;
	// 		z-index: 20;

	// 		.sub-menu-container {
	// 			@apply opacity-100;
	// 		}
	// 	}
	// }

	&__item:hover {
		.c-site-nav__sub-menu-wrapper {
			max-height: 9999px;
			z-index: 12;

			.sub-menu-container {
				@apply opacity-100;
			}
		}
	}

	&__link {
		font-size: 14px;
		height: 110px;
		@apply relative flex items-center px-5 text-navy whitespace-nowrap;
		@screen lg {
			font-size: 18px;
		}
	}

	&__link.active {
		@apply font-bold;
	}

	.c-site-nav__item { 
		&:nth-child(3) {
			.sub-menu-container {
				.row {
					.column {
						&:first-child {
							@apply relative;
							min-height: 140px;

							&:before {
								content: "";
								width: 2px;
								@apply absolute bg-navy left-0 top-0 ml-4;
								height: 106%;
								z-index: -1;
								transform-origin: top left;
								transform: rotate(-20deg);
							}
						}

						&:nth-child(2) {
							@apply flex justify-center items-center;
						}
					}
				}
			}
		}

		&:hover {
			> .c-site-nav__link {
				@apply text-green-200;
			}
		}

	}
}


// Sub menus
.c-site-nav__sub-menu-wrapper {

	top: 110px;
    box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.05);

	@apply overflow-hidden absolute right-0 left-0 max-h-0 w-full bg-white text-navy;

	.sub-menu-container {
		z-index: 0;
		@apply opacity-0 py-16 transition-opacity duration-500 ease-in-out;

		ul {
			li {
				font-size: 16px;
				@apply mb-2;
				@screen xl {
					font-size: 18px;
				}
			}

			a {
				@apply text-navy;
			}

			a.active,
			a:hover {
				@apply text-green-200;
			}
		}
	}
}
