// ==================================================
// Discover Services
// ==================================================

.l-discover-services {
	$top: &;
	padding-top: $section-padding/3;
	padding-bottom: $section-padding;

	&__title {
		margin-bottom: 15px;
		text-align: center;
		color: #fff;
		font-size: 20px;
		font-weight: $font-weight-bold;
		text-transform: uppercase;

		button {
			background-color: transparent;
			border: 0;
		}

		svg {
			stroke: #fff;
		}

		&:hover {
			cursor: pointer;
			color: $dark-gray;

			svg {
				stroke: $dark-gray;
			}
		}
	}
	@include medium {
		&__wrapper {
			@include list-wrapper('.l-discover-services__card', 3, $grid-gutter-width, true);
		}
	}
	@include large-up {
		&__wrapper {
			@include list-wrapper('.l-discover-services__card', 4, $grid-gutter-width, true);
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		padding: 0;

		&:before {
			background-color: #f6f6f6;
		}

		&:focus:before,
		&:hover:before {
			height: 100%;
		}

		&__title {
			text-transform: uppercase;
			padding: 24px;
			margin: 0;
			font-size: 24px;
			font-weight: $font-weight-normal;
			background-color: #fff;
			color: #4a4a4a;
		}

		&__details {
			display: flex;
			flex-direction: column;
			padding: 24px;
			border-bottom-right-radius: 20px;
		}

		.c-card__link-text {
			display: inline-block;
			position: relative;
			left: 0;
			opacity: 1;
			color: $link-color;
			transform: none;
			margin-top: auto;
		}
	}

	&__disclaimer {
		text-align: center;
		color: #fff;
		font-style: italic;
		opacity: 0.5;
		margin-top: 0;
	}
	@include mobile {
		padding-top: $mobile-section-padding/2;
		padding-bottom: $mobile-section-padding;

		&__title {
			font-size: 18px;
		}
	}
}
