// ==================================================
// News & Events Filter
// ==================================================

.c-news-events-filter {

    @apply py-10;

    .l-form {
        @apply flex-wrap;
		@screen lg {
            @apply flex-nowrap;
		}
	}

	.l-form__field {
		@screen lg {
			@apply w-auto mb-0;
		}
	}

	.l-form__field:first-child {
        @apply w-full pr-8;
	}

	.l-form__field:last-child {
		@apply mb-0;
	}

	.l-form__label {
		@apply sr-only;
	}

	[type="search"] {
		@apply w-full;
	}

	[type="submit"] {
        @apply mx-auto;
	}
}
