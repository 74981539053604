// ==================================================
// Videos Webinars
// ==================================================

.l-videos-webinars {
	$top: &;

	&__wrapper {
		@include list-wrapper('.c-video-card');
		position: relative;
	}

	@include desktop {
		&__wrapper {
			min-height: 600px;
		}

		.c-video-card {
			&:first-child {
				position: absolute;
				top: 0;
				left: 0;
				width: 66%;
			}

			&:nth-child(2),
			&:nth-child(3) {
				margin-left: calc(66% + 30px);
				justify-self: flex-end;
			}
		}

		&--pages-1,
		&--pages-2 {
			.c-video-card {
				&:first-child {
					position: relative;
				}

				&:nth-child(2),
				&:nth-child(3) {
					margin-right: 0 !important;
					margin-left: 0 !important;
				}
			}
		}
	}
}
