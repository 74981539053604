// ==================================================
// Print styles
// ==================================================
@media print {
	*,
	*:after,
	*:before {
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	body,
	html {
		font-size: 12px;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}
	/*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: "";
	}

	a[href]:after {
		content: none !important;
	}

	pre {
		white-space: pre-wrap !important;
	}

	blockquote,
	pre {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
    /*
    * Printing Tables
    * http://css-discuss.incutio.com/wiki/Printing_Tables
    */
	thead {
		display: table-header-group;
	}

	img,
	tr {
		page-break-inside: avoid;
	}

	h2,
	h3,
	p {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	.h1,
	h1 {
		font-size: 50px;
	}
	// hide template UI
	#BetterNavigator,
	.c-site-footer,
	.c-site-header {
		display: none !important;
	}

	.l-page-get-in-touch {
		display: none !important;
	}

	article,
	section {
		padding-top: 20px !important;
		padding-bottom: 20px !important;
	}

	.c-share,
	.c-card,
	.l-page-header__breadcrumbs,
	.c-insights,
	.c-featured-people {
		display: none !important;
	}

	.list-tabs__link {
		padding: 0 !important;
		border: 0;
	}

	.c-card {
		display: none !important;
	}

	#contact-form {
		display: none !important;
	}

	.shadow div {
		padding-left: 0 !important;
	}
}
