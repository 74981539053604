// ==================================================
// Job Application Form
// ==================================================

.c-job-application-form {
  position: relative;
  width: 100%;
  margin-top: 3rem;

  &__title {
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1.49px;
    font-weight: $font-weight-medium;
    margin-bottom: 2em;
  }

  .l-form__field:not(.l-form__field--file) .l-form__label {
    @apply sr-only;
  }

  .l-form__label {
    color: #4a4a4a;
    font-weight: $font-weight-medium;
  }

  .l-form__field {
    &--textarea {
      width: 100%;
    }
  }

  @include desktop {
    .l-form__field {
      &--file {
        width: calc(100% - 300px - 1rem);
        margin-bottom: 10px;
      }
    }

    [type="submit"] {
      position: absolute;
      bottom: 20px;
      right: 0;
      min-width: 300px;
    }
  }

  @include mobile {
    [type="submit"] {
      width: 100%;
    }
  }
}
