// ==================================================
// Pagination
// ==================================================

.c-pagination {
	$top: &;

    @apply flex;
    @screen lg {
        @apply justify-end;
    }

	&__link,
	&__next-link,
	&__prev-link,
	&__spacer {
		min-width: 26px;
        line-height: 1;
        @apply inline-block mx-2 text-center;
	}

	&__link {
        @apply pb-2 border-b-4 border-transparent;
		&:hover {
			@apply border-green-200;
		}
	}

	&__current {
		@apply font-bold border-green-200;
	}

	&__next-link,
	&__prev-link {
		@apply font-bold;
	}

	&--white {
		#{$top}__link:not(:hover),
		#{$top}__spacer {
            @apply text-white;
		}

		#{$top}__current,
		#{$top}__next-link,
		#{$top}__prev-link {
			&:not(:hover) {
				@apply text-white;
			}
		}
	}
}
