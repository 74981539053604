// ==================================================
// Sitemap Menu
// ==================================================

.c-sitemap-menu {
	$top: &;

    font-size: 18px;
	margin-top: $mobile-site-header-height;
	z-index: $z-index-sitemap-menu;
	pointer-events: none;
	@apply opacity-0 overflow-auto fixed top-0 left-0 w-full h-full py-8 bg-white;

	button {
		@apply bg-transparent border-0;
	}

    svg path {
        @apply fill-navy;
    }

	&__link,
	&__sub-nav__title {
		@apply flex justify-between items-center px-4 py-3 bg-white border-b border-grey-100;

		button {
			@apply mx-4 p-2;
		}
	}

	&__parent-title {
		@apply px-4 my-2;

		button {
			@apply mr-2 p-2 pl-0;
		}
	}

    &__link:hover,
    &__sub-nav__title:hover {
		@apply bg-green-400 text-green-200;
        svg path {
            @apply fill-green-200;
        }
	}

	&__nav {
		opacity: 1;
	}

	&__sub-nav,
	.c-accordion__content {
		top: $mobile-site-header-height;
		width: 100vw;
		height: calc(100vh - (#{$mobile-site-header-height})) !important;
		z-index: 2;
		transform: translateX(100%);
		@apply overflow-y-scroll fixed left-0 py-8 bg-white transition-transform duration-300 ease-in-out;

		.c-accordion__content {
			top: 0;
		}

		&.active {
			transform: translateX(0);
		}
	}

	.c-accordion__content {
		z-index: 3;
	}
}

.sitemap-menu-open {
	@apply overflow-hidden;

	.c-sitemap-menu {
		pointer-events: all;
		opacity: 1;
	}

	.c-site-nav {
		opacity: 0;
		pointer-events: none;
	}

	.grid-box {
		padding: 120px 15px 0;
	}
}
