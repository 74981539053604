.section-sticky-menu {
	border-top: 1px solid #0D1435;
	@apply  top-0; 

	.accordion {
		@apply border border-solid border-black p-3;

		&__link {
			@apply  uppercase  tracking-wider relative w-full block;

			&:before {
				content: "";
				@apply absolute right-0 top-0 w-4 h-4 bg-no-repeat mt-0.5;
                background-size: 16px;
                background-position: center; 
			}

			&.is-active {
				&:before { 
					transform: rotate(180deg);
				}
			}
		}

		&__content {

			ul {
				@apply py-2 pl-4;

				li {
					a {
						@apply py-1 block;
					}
				}
			}

			&.is-active {
				&:after {
					@apply hidden;
				}
			}
		}
	}

	.desktop-list {
		li {
			@apply uppercase tracking-wider  pr-6;

			@screen xl {
				@apply pr-16;
			}

			a {
				@apply py-5 inline-block relative;

				&:after {
					content: "";
					@apply absolute w-full h-1 bg-green-200 bottom-0 left-0 mb-0 opacity-0 transition-all duration-300 ;
				}

				&.active {
					&:after {
						@apply opacity-100 absolute w-full h-1 bg-green-200 bottom-0 left-0 mb-0 transition-all duration-300;
					}
				}

				&:hover {
					&:after {
						@apply opacity-100;
					}
				}

			}
		}
	}

}

body.fixed-nav {
	.section-sticky-menu { 
		@screen lg {
			@apply fixed z-10; 
			top: 6.85rem;
		}

		@screen xl { 
			top: 6.85rem;
		}

		@screen xxl { 
			top: 6.85rem;
		}
	}
}

.menu-dropdown {
    .wysiwyg-block {
        ul {
            li {
                &:before {
                    @apply hidden;
                }
                a {
                    @apply no-underline;
                    padding: 0 !important;
                }
            }
        }
    }
}

.sticky-links{
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	@apply text-white;
	:hover{
		@apply text-green-200;
	}
}

section {
	scroll-margin-top: 0px;

    @screen lg {
        scroll-margin-top: 146px;
    }

    @screen xl { 
        scroll-margin-top: 154px;
    }

     @screen xxl { 
        scroll-margin-top: 149px;
    }
}
