// ==================================================
// Feature block
// ==================================================

.c-feature {
    @apply relative flex flex-wrap items-center bg-grey-100;
    @screen lg {
        min-height: 460px;
    }
}

.c-feature__image {
    @apply w-full;
    @screen lg {
        @apply overflow-hidden absolute top-0 bottom-0 w-1/2;
    }
}

.c-feature__image:before {
    @screen lg {
        content: '';
        transform: translateX(90%) translateY(-50%) skewX(22deg);
        height: 1000px;
        width: 1000px;
        top: 50%;
        @apply absolute right-0 bg-grey-100;
    }
}

.c-feature__image img {
    @screen lg {
        @apply block w-full h-full object-cover;
    }
}

.c-feature__text {
    @apply w-full;
    .c-feature__links {
        margin-top: 20px;
        .link-text {
            margin-left: 40px;
        }
    }
}

.c-feature__image-grey:before {
    @screen lg {
        content: '';
        transform: translateX(90%) translateY(-50%) skewX(22deg);
        width: 1000px;
        top: 50%;
        @apply absolute right-0 bg-green-400;
    }
}
