// ==================================================
// Team Filter
// ==================================================

.c-team-filter {

	$top: &;

	.c-team-title button {
		width: 50px;
		height: 45px;
		border: 0;
		background-color: transparent;
	}

	.l-form__input {
		border-radius: 5px;
	}

	.l-form__label {
		@apply sr-only;
	}

	.field.l-form__input--select {
		@apply w-full;

		@screen md {
			width: calc(33.33% - 20px);
		}
		@apply px-0;
		label {
			@apply hidden;
		}

		select {
			border-radius: 5px;
			border: 1px solid #D6D6D6;
		}
	}

	.field.text.l-form__input {
		@apply w-full;
		
		@screen md {
			width: calc(100% - 255px);
		}
		margin-bottom: 0;

		@apply px-0 py-0;
		label {
			@apply hidden;
		}

		input {
			border-radius: 5px;
			border: 1px solid #D6D6D6;
		}
	}

	&__clear {
        @apply mr-8;

		svg {
            margin-top: -4px;
			@apply inline-block mr-1;
		}
	}

    &__toggle {
        @apply ml-2;
        path {
            @apply stroke-navy;
        }
    }


	@include mobile {


		&__title {
			position: relative;
			margin-bottom: 0;
			//color: #fff;
			button {
				position: absolute;
				top: 50%;
				right: 0;
				width: 50px;
				height: 45px;
				transform: translateY(-50%);
				border: 0;
				background-color: transparent;

				&.active svg {
					transform: rotate(-180deg);
				}
			}

			svg {
				vertical-align: middle;
				stroke: #fff;
				transition: transform $transition-duration;
			}
		}

		&__form-wrapper {
			height: 0;
			overflow: hidden;
			transition: height $transition-duration;
		}

		.l-form {
			padding-top: 1rem;
		}

		.l-form__field--dropdown,
		.l-form__field--text {
			width: 100%;
		}

		[type="submit"] {
			margin-top: 1rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
