// ==================================================
// Coookies
// ==================================================

#onetrust-consent-sdk,
#onetrust-policy-text {
    font-size: 16px;
    line-height: 1.4;
    @apply font-sans font-light text-navy;
}

#onetrust-accept-btn-handler,
#onetrust-reject-all-handler,
.save-preference-btn-handler {
    font-weight: 400 !important;
}

#onetrust-accept-btn-handler,
.save-preference-btn-handler {
    border-radius: 100px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}
