// ==================================================
// Discover Page
// ==================================================

.DiscoverPage {

    @apply bg-green-400;

	.l-page-contact-form {
        @apply bg-transparent;
	}
}
