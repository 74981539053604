// ==================================================
// Card
// ==================================================

.c-card {
    @apply p-6 mb-6 shadow;
    @screen lg {
        @apply px-8 py-9 mb-8;
    }
}

.c-card:hover {
    @apply shadow-hover;
}

.c-card__link {
    @apply flex flex-wrap items-center;
}

.c-card__title {
    @apply transition duration-300 ease-in-out;
}

.c-card__link:hover .c-card__title {
    @apply text-green-300;
}

.person-card {
    max-width: 350px;
    flex-basis: 300px;
    flex-grow: 1;
    -webkit-box-flex: 1;
     @screen md { 
        min-width: 333px;
     }

     .people-email {
        font-size: 13px;

        @screen md {  
            font-size: 15px;
        }
     }
}
