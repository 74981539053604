// ==================================================
// History
// ==================================================

.l-history__wrapper {
	@apply relative overflow-hidden;
}

.l-history__divider-line line,
.l-history__event__line line {
	stroke: #979797;
	stroke-width: 2;
	stroke-dasharray: 7;
}

.l-history__divider-line {
	left: 50%;
	width: 1px;
	pointer-events: none;
    z-index: 0;
	@apply absolute top-0 overflow-hidden h-full;
}

.l-history__event {
    z-index: 1;
    @apply relative bg-white shadow p-6 ml-2 mr-2 mb-12;
    @screen md {
    	width: calc(50%);
    	min-height: 300px;
        @apply flex flex-wrap bg-transparent shadow-none p-0 m-0;
    }
}

.l-history__event:nth-child(odd) {
	@screen md {
		transform: translateY(-50%);
		@apply absolute right-0;
	}
}

.l-history__event__details {
    h3 {
        @apply text-green-200;
		text-transform: none;
    }
    @screen md {
    	width: calc(100% - 60px);
        @apply pr-4;
    }
    @screen lg {
        width: calc(100% - 100px);
    }
}

.l-history__event:nth-child(odd) .l-history__event__details {
    @screen md {
        order: 2;
        @apply pr-0 pl-4;
    }
}

.l-history__event__line {
    @apply hidden;
    @screen md {
	    width: 60px;
    	height: 1px;
        @apply block overflow-hidden mt-16;
    }
    @screen lg {
        width: 100px;
    }
}

.l-history__event:nth-child(odd) .l-history__event__line {
    @screen md {
        order: 1;
    }
}
