// ==================================================
// Person Card
// ==================================================

.c-featured-people__carousel .swiper-slide {
	will-change: opacity;
	@apply opacity-25 transition-opacity duration-500 ease-in-out;

	 &:last-child {

    	margin-right: calc(100% - (100% / 3.2));

    	@screen md {
    		margin-right: calc(100% - (100% / 2.5));
    	}

    	@screen lg {
    		margin-right: calc(100% - (100% / 3));
    	}
    }
}
.c-featured-people__carousel .swiper-wrapper {
	pointer-events: none;
}

.c-featured-people__carousel .swiper-wrapper > * {
	pointer-events: auto;
}

.c-featured-people__carousel .swiper-wrapper:hover > *,
.c-featured-people__carousel .swiper-wrapper:hover .swiper-slide-active {
	@apply opacity-25;
}

.c-featured-people__carousel .swiper-slide-active,
.c-featured-people__carousel .swiper-wrapper:hover > .swiper-slide:hover {
	@apply opacity-100;

	img {
		filter: grayscale(0%) brightness(100%);
	}
}

.c-featured-person-card {
	max-width: 316px;
	@apply pr-4;
	@screen md {
		max-width: 372px;
		@apply pr-8;
	}
}

.c-featured-person-card__link img {
	filter: grayscale(100%) brightness(75%);
	transition: filter $transition-duration;
	@apply bg-navy;
}

.c-featured-person-card__link:hover img {
	filter: grayscale(0);
}

.c-featured-person-card__image {
	@apply mb-10;
}

.c-featured-people {
	// removed overflow  hidden as causing issue with scroll top
	// @apply relative overflow-hidden;
	@apply relative;

	h2 {
		@apply bg-green-400 relative;
	}

	&:before {
		content: "";
		width: 2px;
		@apply absolute bg-green-200 left-0 top-0 ml-4;
		height: 135px;
		z-index: 0;
		transform-origin: top left;
		transform: rotate(-20deg);

		@screen lg {
			@apply hidden;
		}
	}

	.row {
		&:first-child {
			.column {
				@apply relative;
				@screen lg {
					&:before {
						content: "";
						width: 2px;
						@apply absolute bg-green-200;
						top: -128px;
		               	left: 120px;
		               	transform: rotate(-20deg);
						height: 229px;
						z-index: 0;
					}
				}
			}
		}
	}
}
