// ==================================================
// Engage Us Page
// ==================================================

.EngageUsPage {

	// .c-form-message--success {
	// 	background-color: #fff;
	// 	color: $brand-success;
	// }

	@include desktop {
		.l-payment-cta {
			position: relative;
			z-index: 2;
			padding-bottom: 0;
		}

		.l-payment-cta + .l-banner {
			margin-top: -90px;
		}

		.l-banner {
			max-height: 350px;
		}

		.middleColumn {
			white-space: nowrap;
		}

		.middleColumn * {
			white-space: normal;
		}

		.fieldgroup-field#EngageUsForm_Title_Holder,
		.fieldgroup-field#EngageUsForm_Name_Holder {
			display: inline-block;
			vertical-align: top;
		}

		.fieldgroup-field#EngageUsForm_Title_Holder {
			width: 20%;
		}

		.fieldgroup-field#EngageUsForm_Name_Holder {
			width: 79.6%;
		}
	}
}

#FieldAccountName #select2-AccountName-container {
	padding: 10px 20px;
	line-height: 28px;

	@include mobile {
		padding: 10px 14px;
		line-height: 24px;
	}
}

#FieldAccountName .select2-selection.select2-selection--single {
	height: auto;

	.select2-selection__arrow {
		display: none;
	}
}
