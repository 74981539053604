// ==================================================
// Site Header
// ==================================================

.c-site-header {
	z-index: $z-index-header;
	height: $mobile-site-header-height;
	@apply fixed top-0 left-0 w-full bg-white transition-colors duration-300 ease-in-out;
	@screen lg {
		height: $site-header-height;
	}

	.sitemap-menu-open & {
		@apply bg-grey-100;
	}

	&__default-img {
		width: 121px;
		height: 27px;

		@screen lg {
			width: 163px;
			height: 37px;
		}
	}
}

.c-site-header--sticky {
	box-shadow: 0 8px 8px 0 rgba(0,0,0,0.05);

	.sitemap-menu-open & {
		@apply shadow-none;
	}
}

.c-site-header__inner {
	height: $mobile-site-header-height;
	@screen lg {
		height: $site-header-height;
	}
}

.c-site-header__reverse-img {
	@apply hidden;
}

.hover-img {
	transition: all 0.3s;
	&:hover {
		img {
			@apply opacity-50;
		}
	}
}
// --------------------------------------------------
// Hamburger icon
// --------------------------------------------------

.menu-icon {
	width: 44px;
	height: 24px;
	@apply relative inline-block cursor-pointer;
}

.menu-icon__inner,
.menu-icon__inner:after,
.menu-icon__inner:before {
	content: '';
	height: 3px;
	width: 100%;
	@apply absolute block left-0 bg-navy transition-transform duration-300 ease-in-out;
}

.menu-icon__inner {
	top: 50%;
	transform: translateY(50%);

	.sitemap-menu-open & {
		background-color: transparent;
	}
}

.menu-icon__inner:before {
	top: -10px;

	.sitemap-menu-open & {
		top: 0;
		transform: rotate(45deg);
	}
}

.menu-icon__inner:after {
	bottom: -10px;

	.sitemap-menu-open & {
		bottom: 0;
		transform: rotate(-45deg);
	}
}


// --------------------------------------------------
// Page specific styles
// --------------------------------------------------

.HomePage {
    &:not(.sitemap-menu-open) {
        .c-site-header {
            @apply bg-transparent;
        }

        .c-site-header--sticky {
            @apply bg-white;
        }
    }
}

.HomePage {
	.c-site-header {
		&:hover {
			@apply bg-white;

			.c-site-nav__link {
				@apply text-navy;

				&:hover {
					@apply text-green-200;
				}
			}
		}
	}
}
//
// .ArticleHolderPage,
// .TeamPage {
// 	&:not(.sitemap-menu-open) {
// 		.c-site-header__default-img {
// 			@apply hidden;
// 		}
//
// 		.c-site-header--sticky .c-site-header__default-img {
// 			@apply block;
// 		}
//
// 		.c-site-header__reverse-img {
// 			@apply block;
// 		}
//
// 		.c-site-header--sticky .c-site-header__reverse-img {
// 			@apply hidden;
// 		}
//
//         .c-site-header:not(.c-site-header--sticky) {
//             .menu-icon__inner,
//             .menu-icon__inner:after,
//             .menu-icon__inner:before {
//                 @apply bg-white;
//             }
//         }
// 	}
//
//
// 	.c-site-header {
// 		&:hover {
// 			.c-site-header__default-img {
// 				@apply block;
// 			}
// 			.c-site-header__reverse-img {
// 				@apply hidden;
// 			}
// 		}
// 	}
// }
