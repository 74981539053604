// ==================================================
// Site Footer
// ==================================================

.c-site-footer__inner {
    background-image: url('/resources/themes/base/images/footer.png');
    background-repeat: no-repeat;
    background-size: cover;
    @screen md {
        background-size: contain;
    }
    @screen xl {
        background-position: left 5% center;
    }
    @screen xxl {
        background-position: left 15% center;
    }
}

.c-site-footer__link {
    @apply border-b border-transparent;
}

.c-site-footer__link:hover {
    @apply border-white;
}

.c-site-footer__icon {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    @apply inline-flex justify-center items-center bg-white;
    @screen lg {
        @apply bg-green-400;
    }
}

.c-site-footer__icon + .c-site-footer__icon {
    @apply ml-2;
}

.c-site-footer__icon:hover {
    @apply bg-green-200;
}

.c-site-footer__icon svg {
    @apply fill-navy;
    @screen lg {
        @apply fill-white;
    }
}
