// ==================================================
// Banner
// ==================================================

.l-banner {
	position: relative;
	display: none;
	justify-content: center;
	width: 100%;
	height: 640px;
	background-color: $dark-gray;
	background-image: linear-gradient(to left, rgba(#383838, 1) 0%, rgba(0, 0, 0, 1) 100%);
	overflow: hidden;
	@screen lg {
		display: flex;
	}
	@include pseudo(after) {
		width: 50%;
		z-index: 1;
		background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
		pointer-events: none;
	}

	picture {
		display: flex;
		width: 100%;
		height: 100%;
	}

	img {
		object-fit: cover;
		flex: 1;
	}

	&--height {
		img {
			object-fit: none;
			height: 100%;
		}
	}

	@include mobile {
		height: 320px;
	}
}
