// ==================================================
// Page Nav
// ==================================================

.c-page-nav {
    @screen lg {
        @apply block border-b border-navy;
    }

    ul {
    	@apply overflow-x-auto whitespace-nowrap;
    }
}

.c-page-nav__item {
    @apply inline-block;
}

.c-page-nav__link {
	font-size: 18px;
	border-bottom: 10px solid transparent;
	@apply inline-block pb-2 px-5 transition duration-300 ease-in-out;
}

.c-page-nav__active,
.c-page-nav__link:hover {
	@apply border-green-200;
}
