// ==================================================
// Mixins
// ==================================================

/**
 * Pseudo element
 */
@mixin pseudo($pos: 'before') {
	&:#{$pos} {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		@content;
	}
}

/**
 * Used for a grid of child items - 3 column
 */
@mixin list-wrapper($child, $row-count: 3, $margin: $grid-gutter-width, $centered: false) {
	display: flex;
	flex-wrap: wrap;
	@if $centered == true {
		justify-content: center;

		#{$child} {
			margin: $margin $margin/2;
		}
	} @else {
		justify-content: space-between;
	}

	#{$child} {
		width: calc(#{100%/$row-count} - #{($margin*2) / $row-count});
		margin-top: $margin;
		margin-bottom: $margin;
	}
	@if $centered != true {
		@include desktop {
			#{$child} {
				&:last-child:nth-child(#{$row-count}n + 2) {
					margin-left: $margin;
					margin-right: auto;
				}

				&:last-child:nth-child(#{$row-count}n + 1) {
					margin-left: 0;
				}
			}
		}
		@include mobile {
			#{$child} {
				width: 100%;
				margin-top: $margin/2;
				margin-bottom: $margin/2;
			}
		}
	}
}

@mixin card-hover {
	z-index: 1;

	&:before {
		height: calc(100% + 20px);
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
	}

	.c-card__link-text {
		display: inline-block;
		color: $brand-secondary;
		transform: translateY(0);
		opacity: 1;
	}
}

// Responsive mixins
@mixin mobile-landscape {
	@media screen and (max-width: #{$screen-xs-max}) and (orientation: landscape) {
		@content;
	}
}

@mixin mobile-portrait {
	@media screen and (max-width: #{$screen-xs-max}) and (orientation: portrait) {
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: #{$screen-xs-max}) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: #{$screen-sm-min}) {
		@content;
	}
}

@mixin medium {
	@media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$container-width-large - 1px}) {
		@content;
	}
}

@mixin medium-up {
	@media screen and (min-width: #{$screen-sm-min}) {
		@content;
	}
}

@mixin large {
	@media screen and (min-width: #{$container-width-large}) and (max-width: #{$container-width-xlarge - 1px}) {
		@content;
	}
}

@mixin large-up {
	@media screen and (min-width: #{$container-width-large}) {
		@content;
	}
}

@mixin xlarge {
	@media screen and (min-width: #{$container-width-xlarge}) {
		@content;
	}
}
