// ==================================================
// Newsletter Form
// ==================================================


.c-newsletter-form {

    &__field {
        @screen lg {
            @apply mt-6;
        }
    }
}

.c-newsletter-form__field .l-form__input {
    font-size: 16px;
    @apply p-0 pb-2 bg-transparent border-b border-navy rounded-none w-full;
    @screen lg {
        font-size: 18px;
    }

    &::placeholder {
        @apply text-navy;
    }
}
