// ==================================================
// Resources
// ==================================================

.l-resources {
	&__table {
		width: 100%;

		td:first-child {
			border-left: 0;
		}

		&__category,
		&__download,
		&__file-info,
		&__title {
			padding-top: 30px;
			padding-bottom: 30px;
			border-right: 0;
		}

		tr:last-child {
			border-bottom: 0;
		}

		&__category {
			min-width: 200px;
			font-size: 14px;
			color: $light-gray;
			white-space: nowrap;
		}

		&__title {
			//width: 100%;
		}

		&__file-info {
			white-space: nowrap;
		}

		&__download,
		&__file-info {
			padding-left: 30px;
			padding-right: 30px;
		}

		&__file-info {
			color: #a7a9ac;
			text-transform: uppercase;
		}

		&__download a {
			text-transform: uppercase;
			font-weight: $font-weight-medium;
			letter-spacing: 1.31px;
			@apply text-green-200;

			&:hover {
				@apply text-navy;
			}
		}
	}
	@include mobile {
		&__table {
			tr {
				display: flex;
				flex-wrap: wrap;
			}

			&__category,
			&__download,
			&__file-info,
			&__title {
				width: 100%;
				min-width: 0;
				padding: 0;
				order: 1;
			}

			&__title {
				order: 0;
				font-size: 1.1rem;
				padding-top: 1rem;
			}

			&__download {
				padding-top: 1rem;
				padding-bottom: 1rem;
			}

			&__file-info {
				font-size: 0.8rem;
			}
		}
	}
}
