// ==================================================
// Page
// ==================================================

.l-page {
    margin-top: $mobile-site-header-height;
    @screen lg {
        margin-top: $site-header-height;
    }
}

.l-page-header {
    margin-top: $mobile-site-header-height;
    @apply relative bg-green-400;
    @screen lg {
        margin-top: $site-header-height;
    }
}

.l-page-header__inner {
    @screen lg {
        height: 566px;
    }
}

.l-page-header__text {
    @apply py-8 pl-4;
    @screen lg {
        @apply w-1/2;
    }
    @media only screen and (min-width: 1332px) {    // Container width
        padding-left: calc((100% - 1300px) / 2);
    }
    @screen xl {
        width: 40%;
    }
}

.l-page-header__image {
    @apply relative;
    @screen lg {
        max-width: 1200px;
        @apply overflow-hidden absolute top-0 right-0 bottom-0 h-full w-1/2;
    }
    @screen xl {
        width: 60%;
    }
}

.l-page-header__image:after {
    @screen lg {
        content: '';
        transform: translateX(-88%) translateY(-50%) skewX(20deg);
        height: 1000px;
        width: 1000px;
        top: 50%;
        @apply absolute left-0 bg-green-400;
    }
}

.l-page-header--team {
    @apply bg-grey-100;
    .l-page-header__image:after {
        @apply bg-grey-100;
    }
}

.l-page-header__breadcrumbs {
    @screen lg {
        font-size: 16px;
    }
    a {
        padding-bottom: 2px;
        &:hover {
            border-bottom: 1px solid $white;
        }
    }
}

.l-page-header__breadcrumbs {
    li {
        @apply inline-block;
    }
    li + li:before {
        content: '>';
        padding: 0 12px 0 8px;
    }
}


// --------------------------------------------------
// Full height banner
// --------------------------------------------------

.l-page-full-header {
    @apply relative bg-green-400 overflow-hidden;
}

.l-page-full-header__inner {
    @screen lg {
        height: 674px;
    }
}

.subtract {
    @apply absolute bottom-0 -ml-8;
    width: 930px !important;

    @screen sm {
        @apply -mb-16 -ml-20;
    }

    @screen md {
        @apply -mb-24 -ml-32;
    }

    @screen lg {
        @apply -ml-8;
    }
}


.l-page-full-header__text {
	padding-top: $site-header-height;
    @apply pb-12 pl-4 z-10;
    width: 100%;

    @screen lg {
        @apply pb-24;
    }
    @media only screen and (min-width: 1332px) {    // Container width
        padding-left: calc((100% - 1300px) / 2);
    }

    h1 {
        width: 65%;
        font-size: 36px;

        @screen lg {
            width: 100%;
            font-size: 2.5rem;
        }
        @screen xl {
            font-size: 3.3rem;
        }
    }

    .btn {
        @apply whitespace-nowrap;
    }
}

.HomePage {
    .l-page-full-header__text {
        @screen lg {
            padding-top:0;
            padding-bottom:0;
            padding-top:40px;
        }
        h1 {
            width: 90%;
            font-size: 32px;
            @screen md {
                width: 70%;
                font-size: 40px;
            }
            @screen lg {
                width: 100%;
                font-size: 2.5rem;
            }
            @screen xl {
                font-size: 2.9rem;
            }
        }
    }
}

.l-page-full-header__textinner {
    width:70%;
    @screen lg {
        width: 30%;
    }
}

.l-page-full-header__image {
    video {
        display:none;
    }
    @screen lg {
        width: 59%;
        transform: none;
        @apply overflow-hidden absolute top-0 right-0 bottom-0 h-full;
        video {
            display:block;
        }
    }
}

.l-page-full-header--home .l-page-full-header__image {
    @apply absolute right-0;
    height: 141%;
    right: -27%;
    top: 50%;
    transform: rotate(-20deg) translateY(-50%);
    transform-origin: top left;
    width: 57%;
    @screen lg {
        width: 62%;
        transform: none;
        @apply overflow-hidden absolute top-0 right-0 bottom-0 h-full;
    }
}

.l-page-full-header__image:after {
    @screen lg {
        content: '';
        transform: translateX(-88%) translateY(-50%) skewX(18.5deg);
        height: 1200px;
        width: 1200px;
        top: 50%;
        @apply absolute left-0 bg-green-400;
    }
}


// --------------------------------------------------
// Home page full height banner
// --------------------------------------------------

.l-page-full-header--home {
    @apply bg-white;
}

.l-page-full-header--home .l-page-full-header__image {
    @screen lg {
        @apply overflow-visible;
    }
}

.l-page-full-header--home .l-page-full-header__image:before {
    @screen lg {
        content: '';
        transform: translateX(-78.5%) translateY(-50%) skewX(0deg);
        width: 1200px;
        top: 50%;
        z-index: 1;
        @apply absolute left-0 bg-no-repeat bg-right-bottom h-full;
        background-image: url('/resources/themes/base/images/svg/subtract-dt.svg');
    }
}

.l-page-full-header--home .l-page-full-header__image:after {
    @screen lg {
        @apply bg-white;
        //background-image: url('/resources/themes/base/images/svg/subtract-dt.svg') #ffffff;
       // @apply bg-no-repeat bg-transparent;
    }
}

