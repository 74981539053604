// ==================================================
// Insights
// ==================================================

.c-insights-filter {
	$top: &;

	.l-form__label {
		@apply sr-only;
	}

	.c-team-filter__clear {
		right: 15px;
		top: 0;
	}

	.c-insights-title button {
		width: 50px;
		height: 45px;
		border: 0;
		background-color: transparent;
	}

	.field.l-form__input--select {
		@apply w-full;

		@screen md {
			width: calc(33.33% - 20px);
		}

		@apply px-0;
		label {
			@apply hidden;
		}

		select {
			border-radius: 5px;
			//border: 1px solid #D6D6D6;
		}
	}

	.field.text.l-form__input {
		@apply w-full;

		@screen md {
			width: calc(100% - 255px);
		}
		margin-bottom: 0;

		@apply px-0 py-0;
		label {
			@apply hidden;
		}

		input {
			border-radius: 5px;
			//border: 1px solid #D6D6D6;
		}
	}

	&__clear {

		svg {
            margin-top: -4px;
			@apply inline-block mr-1;
		}
	}

	[type="submit"] {
		width: 225px;
		border: 0;
		padding-left: 0;
		padding-right: 0;
	}

	@include mobile {

		&__form-wrapper {
			height: 0;
			overflow: hidden;
			transition: height $transition-duration;
		}

		.l-form {
			padding-top: 1rem;
		}

		.l-form__field--dropdown,
		.l-form__field--text {
			width: 100%;
		}

		[type="submit"] {
			margin-top: 1rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.field.checkboxset {
		width: 100%;
		@media screen and (min-width: 768px) {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
		}

		> label {
			@apply text-navy;
			width: 115px;
			font-size: 14px;
			font-weight: 700;
			margin: 5px 0 10px;
			padding: 0;
			display: block;
			opacity: 1;
			@media screen and (min-width: 1201px) {
				font-size: 15px;
			}
		}

		.middleColumn {
			width:100%;
			@media screen and (min-width: 768px) {
				width: calc(100% - 115px);
			}
		}
	}

	.control-me {
		@apply text-navy;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		background: transparent;
		padding: 11px 25px;
		border-radius: 5px;
		border: 1px solid rgba(167, 169, 172, 0.4);
		z-index: 0;
		font-weight: 300;
		font-size: 14px;
	}

	.checkbox-item {
		position: relative;
		display: inline-block;
		margin: 0 10px 20px 0;
		label {
			z-index:1;
		}
		&:hover {
			.control-me {
				background: rgba(30, 237, 147, var(--tw-bg-opacity));
				cursor: pointer;
			}
		}
	}

	.toggle:checked ~ .control-me {
		@apply bg-navy;
		border: 1px solid #dddddd;
		color: #FFF;
	}

	label {
		position: relative;
		background: transparent;
		padding: 11px 25px;
		border-radius: 9999px;
		border: 1px solid transparent;
		display: inline-block;
		opacity: 0;
		cursor: pointer;
		font-size: 14px;
	}

	.visually-hidden {
		position: absolute;
		left: -100vw;
	}
}


// --------------------------------------------------
// Insights carousel
// --------------------------------------------------

.c-insights__carousel {

    .swiper-container {
        @apply pt-2 pb-4 pl-3;    // For box shadows
    }

    .swiper-slide {
        max-width: 324px;
        will-change: opacity;
        @apply opacity-25 h-auto pr-4 transition-opacity duration-500 ease-in-out;
        @screen lg {
            max-width: 440px;
            @apply pr-8;
        }

        &:last-child {

        	margin-right: calc(100% - (100% / 5));


        	@screen md {
        		margin-right: calc(100% - (100% / 2));
        	}

        	@screen lg {
        		margin-right: calc(100% - (100% / 1.3));
        	}
        }
    }

    .swiper-slide-active + .swiper-slide {
        @screen md {
            @apply opacity-100;
        }
    }

    .swiper-slide-active + .swiper-slide + .swiper-slide {
        @screen xxl {
            @apply opacity-100;
        }
    }

    .swiper-slide:hover,
    .swiper-slide.swiper-slide-active {
        @apply opacity-100;
    }
}


// --------------------------------------------------
// Insights card
// --------------------------------------------------

.c-insights-card {
    transition-property: box-shadow;
	@apply block h-full shadow duration-300 ease-in-out;
	&:hover {
    	@apply shadow-hover;
	}
}

.c-insights-card__text {
	height: calc(100% - 240px);
	@apply block p-6;
    @screen lg {
    	@apply p-8;
    }
}

.c-insights-card__image {
	height: 240px;
	width: 100%;
    @apply block relative;
    img {
        @apply relative w-full h-full object-cover;
    }
}

.c-article-card__btn {
	@apply block relative;
}

.c-article-card__play-icon,
.c-insights-card__play-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	svg circle,
	svg path {
		transition: fill $transition-duration;
	}
}
