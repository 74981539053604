// ==================================================
// Share
// ==================================================

.c-share {
    @apply flex flex-wrap items-center;
}

.c-share__icon {
    width: 42px;
    height: 42px;
    @apply flex justify-center items-center ml-2 bg-green-400 rounded-full;
}

.c-share__icon svg {
    @apply fill-green-200;
}

.c-share__icon:hover svg {
    @apply fill-white;
}
