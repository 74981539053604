// ==================================================
// Contact Locations
// ==================================================

.c-contact-locations {
    @apply bg-green-100 text-green-400;

	a {
        @apply border-b border-transparent;
		&:hover {
			@apply border-current;
		}
	}

    h1, h2, h3, h4, h5, h6,
    address {
        @apply text-green-400;
    }

	dt {
        @apply relative px-5 py-4 bg-green-100 border-b border-green-400;
        @screen lg {
            @apply px-10 py-6;
        }
		&:hover {
            @apply cursor-pointer;
			svg {
                @apply stroke-green-400;
			}
		}
	}

	&__toggle-btn {
		width: 40px;
		outline: none;
        @apply absolute top-0 right-0 h-full bg-transparent;
        @screen lg {
            width: 60px;
        }
		svg {
			stroke-width: 4;
            @apply stroke-green-400 transition-transform duration-300 ease-in-out;
		}
		&:hover {
			@apply cursor-pointer;
			svg {
				@apply stroke-green-400;
			}
		}
	}

	.c-accordion--active &__toggle-btn svg {
		//transform: rotate(45deg);
		.cross {
			@apply hidden;
		}
	}

	&__date {
        @apply w-full ml-0;
		@screen lg {
            font-size: 17px;
            @apply inline-block w-auto ml-4;
        }
	}

	dd {
        @apply ml-0;
	}

	&__inner {
        @apply p-5 border-b border-green-400;
        @screen lg {
            @apply p-10;
        }

		h5 {
            @apply font-bold;
        }

		ul {
            @apply flex flex-wrap mb-8 pl-0;
            @screen lg {
                font-size: 17px;
            }
		}

		li {
            @apply w-full mb-4;
            @screen lg {
                @apply w-1/2 my-2 pr-4;

            }

            &.w-full {
                width: 100% !important;
            }
		}

        li:last-child {
            @apply mb-0;
        }

        li.fw {
            @apply w-full;
        }

        p {
            @screen lg {
                font-size: 17px;
            }
        }
	}
}
