// ==================================================
// Variables
// ==================================================

// --------------------------------------------------
// Misc
// --------------------------------------------------

$transition-duration: 300ms;
$transition-duration-in-view: 1.2s;
$transition-easing-in-view: cubic-bezier(0.165, 0.84, 0.44, 1);

$border-radius-base: 0;

$site-header-height: 110px;
$mobile-site-header-height: 88px;
$site-header-max-width: 1760px;

$small-viewport-max-height: 885px;

$body-border-width: 26px;
$mobile-body-border-width: 5px;

$assets-path: '/resources/themes/base/';

$z-index-footer: 10;
$z-index-sitemap-menu: 11;
$z-index-header: 12;
$z-index-lightbox: 15;

// --------------------------------------------------
// Colours
// --------------------------------------------------

$brand-primary: #00a657;
$brand-tint: #29af50;
$brand-green: #55bc00;
$brand-secondary: #9aca3c;
$brand-bg: #f8f8fa;

$brand-success: $brand-primary;
$brand-info: #5bc0de;
$brand-warning: #ffd600;
$brand-danger: #a63933;

$black: #0a0203;
$dark-gray: #585b5a;
$gray: #95908c;
$light-gray: #a7a9ac;
$white: #fff;

$error: $brand-danger;

$brand-gradient: linear-gradient(135deg, rgba($brand-primary, 1) 0%, rgba($brand-secondary, 1) 100%);

// --------------------------------------------------
// Body
// --------------------------------------------------

$text-color: $dark-gray;
$section-padding: 66px;
$section-padding-large: 110px;

$mobile-section-padding: 45px;
$mobile-section-padding-large: 40px;

// --------------------------------------------------
// Fonts
// --------------------------------------------------

$font-family-sans: "gt-walsheim", "Segoe UI", sans-serif;
$font-family-serif: serif;
$font-family-base: $font-family-sans;
$font-family-body: $font-family-sans;
$font-family-heading: $font-family-sans;

$font-size-base: 14px;
$font-size-large: 16px;
$font-size-small: 12px;
$line-height-base: normal;

$font-weight-light: 300;
$font-weight-book: 400;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 800;

$small: $font-size-small;
$large: $font-size-large;


// --------------------------------------------------
// Links
// --------------------------------------------------

$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 15%);
$link-hover-decoration: none;

// --------------------------------------------------
// Buttons
// --------------------------------------------------

$btn-font-family: $font-family-base;
$btn-letter-spacing: 1px;
$btn-text-transform: uppercase;
$btn-border-width: 2px;
$btn-border-radius: 100px;

// -------------------------
// Btn Regular
// -------------------------

$btn-font-size: 15px;
$btn-height: 52px;
$btn-hrz-padding: 40px;
$btn-line-height: $btn-height - 4px;

// -------------------------
// Btn Small
// -------------------------

$btn-height-small: 45px;
$btn-hrz-padding-small: 40px;
$btn-line-height-small: $btn-height-small - 3px;

// -------------------------
// Btn Large
// -------------------------

$btn-height-large: 60px;
$btn-hrz-padding-large: 45px;
$btn-line-height-large: $btn-height-large - 2px;

// -------------------------
// Label
// -------------------------

$label-font-size: $font-size-base;
$label-color: #4a4a4a;
$label-font-weight: $font-weight-normal;
$label-letter-spacing: 0;
$label-text-transform: none;
$label-margin-bottom: 0.5rem;

// -------------------------
// Textarea
// -------------------------

$textarea-height: 160px;
$textarea-padding: 20px;

// -------------------------
// Form group
// -------------------------

$form-group-margin-bottom: 1rem;

// --------------------------------------------------
// Font-awesome
// --------------------------------------------------

$fa-font-path: '/resources/themes/base/fonts';

// --------------------------------------------------
// Container
// --------------------------------------------------

$container-width-small: 1005px;
$container-width: 1332px;
$container-width-large: 1680px;
$container-width-xlarge: 1920px;

// Grid Gutter
$grid-gutter-width: 32px;

// Columns
$columns: 24;

// --------------------------------------------------
// Grid breakpoints
// --------------------------------------------------

// Extra small screen / phone
$screen-xs: 767px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
$screen-md: 1024px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
$screen-lg: 1440px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

// Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;

// Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

// ------------------------------
// Mobile media query
// ------------------------------

$desktop-size: $screen-md;
$small-mobile: screen and (max-width: 350px);
